exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-templates-collaborator-template-tsx": () => import("./../../../src/templates/collaborator-template.tsx" /* webpackChunkName: "component---src-templates-collaborator-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-posts-template-tsx": () => import("./../../../src/templates/posts-template.tsx" /* webpackChunkName: "component---src-templates-posts-template-tsx" */),
  "component---src-templates-topic-template-tsx": () => import("./../../../src/templates/topic-template.tsx" /* webpackChunkName: "component---src-templates-topic-template-tsx" */)
}

